import React, { useEffect } from 'react'
import ItemCard from '../../components/ItemCard'
import { Grid, useMediaQuery, CircularProgress } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Waypoint } from 'react-waypoint'
import gql from 'graphql-tag'
import { useQuery, useMutation } from '@apollo/react-hooks'
import Loadable from 'react-loadable'
import { isEmpty } from 'lodash'
import QUERY from '../../query'
import { useQueryParam } from 'gatsby-query-params'
import ChipContainer from './ChipContainer'
import { navigate } from 'gatsby'
import { useState } from 'react'
import useOnCloseScrollY from '../../utils/scrollHelper'
const Text2Speech = Loadable({
  loader: () => import('../../components/Text2Speech'),
  loading: () => null,
})

const MORE = gql`
  mutation($type: String!) {
    search(type: $type) @client
  }
`
const SEARCH = gql`
  query(
    $message: String
    $user_id: String!
    $filter: String
    $filter_type: String
  ) {
    search(
      message: $message
      user_id: $user_id
      filter: $filter
      filter_type: $filter_type
    ) {
      ...Result
    }
  }
  ${QUERY.SEARCH_RESULT}
`
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 1800,
  },
  floatChips: {
    top: -50,
  },
  spacingxs2: {
    margin: 0,
  },
}))
const ItemList = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  const isShow = !useOnCloseScrollY({ threshold: 400 })
  // const [state, setState] = useState({
  // cursor: 0,
  // chip: matches,
  // })
  // let cursor = 0
  const searchQuery =
    typeof window !== `undefined` && useQueryParam('search', 'initial')
  const {
    data: {
      session: { user_id },
    },
  } = useQuery(gql`
    query {
      session @client {
        user_id
      }
    }
  `)
  // const handleScroll = ({ currentTarget }) => {
  //   if (cursor > currentTarget.scrollY + 150) {
  //     setState({
  //       ...state,
  //       chip: true,
  //     })
  //     cursor = currentTarget.scrollY
  //   }
  //   if (cursor < currentTarget.scrollY - 150) {
  //     setState({
  //       ...state,
  //       chip: false,
  //     })
  //     cursor = currentTarget.scrollY
  //   }
  //   if (currentTarget.scrollY < 100) {
  //     setState({
  //       ...state,
  //       chip: matches,
  //     })
  //   }
  // }
  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll)
  //   cursor = window.scrollY
  // }, [])
  const classes = useStyles()
  const { data, loading, fetchMore, client } = useQuery(SEARCH, {
    variables: { message: searchQuery, user_id },
    notifyOnNetworkStatusChange: true,
  })
  useEffect(() => {
    client.writeData({
      data: {
        loading: {
          __typename: 'Loading',
          search: loading,
        },
      },
    })
  }, [loading])
  if (data) {
    const {
      documents,
      responseMessage,
      responseLanguage,
      hasMore,
      directiveButtons,
    } = data.search
    return (
      <Grid
        container
        spacing={2}
        className={classes.root}
        classes={{
          'spacing-xs-2': classes.spacingxs2,
        }}
      >
        {!isEmpty(directiveButtons) && (
          // <Grid item xl={12} xs={12}>
          <ChipContainer
            show={isShow}
            directiveButtons={directiveButtons}
            fetchMore={fetchMore}
          />
          // </Grid>
        )}
        <Grid item xl={12} xs={12} style={{ marginTop: 20 }}>
          <Text2Speech text={responseMessage} language={responseLanguage} />
        </Grid>
        {documents.map((props, i) => (
          <Grid
            key={props ? props.docId : i}
            // container
            item
            xl={3}
            lg={3}
            md={4}
            sm={6}
            xs={12}
            justify="center"
          >
            <ItemCard {...props} />
            {i === documents.length - 5 && hasMore && !loading.search ? (
              <Waypoint
                onEnter={() => {
                  fetchMore({
                    variables: {
                      message: null,
                    },
                    updateQuery: (
                      prev,
                      {
                        fetchMoreResult: {
                          search: { documents, ...remain },
                        },
                      }
                    ) => {
                      return {
                        search: {
                          documents: [...prev.search.documents, ...documents],
                          ...remain,
                        },
                      }
                    },
                  })
                }}
              />
            ) : null}
          </Grid>
        ))}
        {hasMore && (
          <Grid item xl={12} xs={12} style={{ marginTop: 20,textAlign: 'center' }}>
            <CircularProgress style={{display: 'inline-box'}}/>
          </Grid>
        )}

      </Grid>
    )
  }
  return null
}

export default ItemList
