import React from 'react'
import { Chip, Slide, useMediaQuery, useTheme } from '@material-ui/core'
import { getSearchParams } from 'gatsby-query-params'
import { navigate } from 'gatsby'
import { makeStyles } from '@material-ui/styles'
import ScrollMenu from 'react-horizontal-scrolling-menu'
const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: 5,
    marginBottom: 5,
  },
  container: {
    position: 'fixed',
    zIndex: 88,
    display: 'flex',
    // flex: '1 1 auto',p;
    // flexDirection: 'row',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    overflowX: 'auto',
    // "::-webkit-scrollbar":{
    //     display: 'none'
    // }
  },
  containerUp: {
    position: 'fixed',
    zIndex: 88,
    bottom: 5,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
    // "::-webkit-scrollbar":{
    //     display: 'none'
    // }
  },
}))
const ChipContainer = ({ directiveButtons, fetchMore, show }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { search, filter = '' } = getSearchParams()
  const onClick = (text, payload) =>
    fetchMore({
      variables: {
        filter: text,
        filter_type: payload,
        message: null,
      },
      updateQuery: (_, { fetchMoreResult }) => {
        navigate(`?search=${search}&filter=${filter + text + ','}`)
        return fetchMoreResult
      },
    })
  const matches = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <>
      <Slide direction={'down'} in={!show} mountOnEnter>
        <div className={classes.container}>
          {directiveButtons.map(({ text, payload }) => (
            <Chip
              size={matches ? 'small' : 'medium'}
              color="secondary"
              className={classes.chip}
              key={text}
              label={text}
              onClick={() => onClick(text, payload)}
            />
          ))}
        </div>
      </Slide>
      <Slide direction={'up'} in={show} mountOnEnter>
        <div className={classes.containerUp}>
          {directiveButtons.map(({ text, payload }) => (
            <Chip
              // variant="outlined"
              size={matches ? 'small' : 'medium'}
              color="secondary"
              className={classes.chip}
              key={text}
              label={text}
              onClick={() => onClick(text, payload)}
            />
          ))}
        </div>
      </Slide>
    </>
  )
}
{
  /* <ScrollMenu
        menuStyle={{ flexWrap: 'nowrap', width: '100%',justifyContent:'flex-start' }}
        wrapperStyle={{width: '100%', display: 'flex',}}
        innerWrapperStyle={{
          width: '100%',
          display: 'flex',
          flexWrap: 'nowrap',
          flexGrow: 1
        }}
        data={directiveButtons.map(({ text, payload }) => (
          <Chip
            color="secondary"
            className={classes.chip}
            key={text}
            label={text}
            onClick={() => onClick(text, payload)}
          />
        ))}
      /> */
}
export default ChipContainer
